aside{
    background-color: #0e7912;
    padding: 10px 0 0;

    .livox-logo img{
        width: 40px;
        text-align: center;
        display: block;
        width: 40px;
        text-align: center;
        display: block;
        margin: 5px auto;
        padding: 5px;
    }

    ul{
        list-style-type: none;
        margin: 0 0 7px;
        padding: 0;
    }
    
    li {
        padding: 4px 20px;
        cursor: pointer;
        user-select: none;
        &:hover {
            background-color: rgba(0, 0, 0, 0.121);
        }
    }
    
    .title{
        padding: 4px 20px;
        margin: 0;
        font-size: 18px;
        font-weight: normal;
        color: #DDD;
    }

    a{
        color: #FFF;
        text-decoration: inherit;
    }

    .link-active{
        font-weight: bold;
    }

}

