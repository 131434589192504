.user-card-area{
    margin-left: auto;
    align-self: center;
    background-color: #00000030;
    color: #fff;

    .user-card{
        cursor: pointer;
        user-select: none;
        display: flex;
        padding: 4px;
        img{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 6px
        }
        .user-name{
            display: block;
            font-size: 16px; 
        }
        .user-customer{
            display: block;
            font-size: 12px; 
        }
        .dropdown-open{
            align-self: center;
            flex-grow: 1;
            text-align: right;
            margin: 0 10px;
        }
        svg{
            transition: 0.12s all linear;
        }
    }

    .dropdown-options{
        transition: 0.2s all linear;
        max-height: 0;
        overflow: hidden;
        height:auto;

        ul{
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
        li {
            padding: 6px 20px;
            cursor: pointer;
            user-select: none;
            &:hover {
                background-color: rgba(0, 0, 0, 0.121);
            }
        }

        a{
            color: inherit;
            text-decoration: inherit;
        }
    }

}

.user-card-area.open {
    .dropdown-options{
        max-height: 100px;
    }
    .user-card svg {
        transform: rotate(180deg);
    }

}