.View{
    width: 80vw;
    flex-grow: 1;
    padding: 0 3vw;
}

.data-field{
    display: inline-block;
    margin: 4px 0;
    .label{
        display: block;
        font-size: 14px;
        font-weight: 300;
    }
    .value{
        font-size: 16px;
    }
}