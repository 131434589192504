@import "./pallete.scss";

body{
  color: #333;
}

.Center{
  display: flex;
  height: 100vh;
  > * {
    overflow: auto;
  }
}

.rtl{
  direction: rtl;
  .pagination button{
    transform: rotate(180deg);
  }
}

.master-form{
  width: 500px;
  margin: 0 auto;
  font-size: 17px;
  button{
    background-color: portal-color('button');
    color: white;
  }
}

.form{

  legend{
    font-size: 26px;
    margin: 15px 0;
    padding: 0 0 4px;
    border-bottom: 1px solid #DEE1E5;
  }
  input, select{
    display: block;
    width: 100%;
    font-size: 16px;
    padding: 10px;
    box-sizing: border-box;
    margin: 0px 0 17px;
    border: 1px solid #DEE1E5;
    background-color: #FFF;
  }
  input[type='checkbox']{
    width: 20px;
    height: 20px;
    vertical-align: sub;
    margin: 10px;
    display: inline-block;
  }
  .error{
    color: #777;
    display: block;
    text-align: right;
    font-size: 14px;
    margin: -17px 0 0;
  }
  label{
    font-size: 17px;
    margin: 0 0 3px;
  }
  .form-buttons{
    display: flex;
  }
  button{
    border: transparent;
    display: inline-block;
    margin: 0 15px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    flex-grow: 1;
    border-bottom: 3px solid portal-color('border');
    border-radius: 3px;
    padding: 10px 10px 7px;
  }

}

.form-creation{

  max-width: 700px;
  margin: 0 auto;

  .field{
    display: inline-block;
    width: 50%;
    box-sizing: border-box;
    padding: 0 10px;
  }
}

button{
  border: transparent;
  display: inline-block;
  margin: 0 15px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  flex-grow: 1;
  border-bottom: 3px solid portal-color('border');
  border-radius: 3px;
  padding: 10px 10px 7px;
  background: portal-color('button');
  color: #fff;
  svg{
    vertical-align: text-top;
  }
}

button.secundary{
  background: #ffffff;
  color: portal-color('button');
  border: 1px solid portal-color('border');
}

.block{
  display: block !important;
}