.search-bar{
    svg{
        padding: 11px;
        vertical-align: bottom;
        background-color: #eeeeee;
        border-color: #DEE1E5;
        border-style: solid;
        border-width: 1px 0 1px 1px;
    }
    input{
        display: inline-block;
        font-size: 16px;
        padding: 10px;
        box-sizing: border-box;
        border: 1px solid #DEE1E5;
        border-width: 1px 1px 1px 0px;
        background-color: #FFF;
        width: 200px;
    }
}