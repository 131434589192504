@import "../pallete.scss";

.data-header{
    display: flex;
    margin: 25px 0;
    justify-content: space-between;
    align-items: center;
    
    h1{
        display: inline-block;
        margin: 0;
    }
    svg{
        vertical-align: top;
        margin: 0 5px;
    }
}

table{
    width: 100%;
    border-spacing: 0;
    table-layout: fixed;
    
    a{
        text-decoration: inherit;
        color: portal-color('dark');   
    }
    
    thead{
        background-color: portal-color('base');
        color: #fff;
    }
    
    select{
        display: block;
        width: 100%;
        font-size: 16px;
        padding: 3px;
        box-sizing: border-box;
        border: 1px solid #DEE1E5;
        background-color: #FFF;   
    }

    th, td{
        text-align: left;
        overflow: auto;
        padding: 3px 6px;
        overflow-wrap: break-word;
    }

    tr:nth-child(even){
        background-color: #f7f7f7;
    }
}

#data-table {
    border: 0 solid portal-color('base');
    border-width: 0 1px 1px;
    th {
        cursor: pointer;
        svg{
            float: right;
            color: #f7f7f7;
            margin-top: 3px;
        }
    }
}

.attribute-search{

    .attr-form-field{
        box-sizing: border-box;
        display: flex;
        border: 1px solid portal-color('dark');

        svg{
            vertical-align: bottom;
            background-color: portal-color('dark');
            padding: 5px 6px 6px;
            font-size: 12px;
        }
    
        input{
            background-color: portal-color('dark');
            border: transparent;
            box-sizing: border-box;
            color: #fff;
            padding: 4px;
            width: 40px;
            flex-grow: 1;
            outline: none;
        }
    
    }
    
}

.table-control{
    display: flex;
    justify-content: space-between;
    margin: 10px 0 5px;
}

.pagination{
    
    display: block;
    text-align: right;
    margin: 10px 0 10px;

    .pagination-label{
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px;

        > span{
            display: block;
            font-size: 12px;
            text-align: center;
        }
        .page{
            font-size: 14px;
        }
    }

    button{
        background-color: #ffffff;
        color: portal-color('dark');
        border: 0px solid;
        font-size: 22px;
        padding: 4px 6px;
        border-radius: 50%;
        cursor: pointer;
        user-select: none;
        outline: none;
        vertical-align: middle;
        margin: 0;

        svg{
            vertical-align: text-bottom;
        }

        &:hover{
            color: #ffffff;
            background-color: portal-color('dark');
        }

        &:active{
            color: #ffffff;
            background-color: #477726;
        }
    }
}
